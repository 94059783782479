import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  about,
  container,
  one,
  two,
  three,
  four,
  five,
  six,
  eight,
  left,
  right,
  info,
  photo,
} from "./about.module.scss";

const img1 = "../../images/about/1.jpg";
const img2 = "../../images/about/2.jpg";
const img3 = "../../images/about/3.jpg";
const img4 = "../../images/about/4.jpg";
const img5 = "../../images/about/5.jpg";
const img6 = "../../images/about/6.jpg";

function Aboutinfo() {
  return (
    <section className={about}>
      <div className={one}>
        <h2>О нас</h2>
        <div className={container}>
          <div>
            <p>
              Работаем с кондитерами с 2019г. В своей работе мы используем
              фанеру сорта ФК, соответствующую ГОСТу. Тщательно шлифуем каждую
              подложку с обеих сторон, делаем красивую четкую гравировку.
              <br /> <br />
              Мы всегда максимально открыты и доступны для обратной связи. И
              придерживаемся принципов уважения по отношению к своему клиенту.
              Поэтому, если мы с вами оформили заказ, то гарантируем его
              исполнение.
              <br /><br />
              Мы сделаем так, чтобы вы остались довольны.
            </p>
          </div>
          <div>
            <p>
              Вся наша продукция сертифицирована и безопасна для использования.
              <br /> <br />
              Мы всегда стремимся быть максимально отрытыми и производить изделия самого высокого качества, соответсвующего всем допустимым параметрам. Поэтому мы прошли добровольную сертификацию своей продукции.
            </p>
          </div>
        </div>
      </div>

      <div className={two}>
        <div className={left}>
          <StaticImage src={img1} alt="wowmilota" placeholder="dominantColor" />
        </div>
        <div className={right}>
          <div className={info}>
            <p>
              <span>
                Мы помогаем вам создавать торты, от которых ваш заказчик будет в
                восторге!
              </span>
              <br />
              <br />
              Wowmilota - это красиво
              <br />
              Wowmilota - это качественно
              <br />
              Wowmilota - это для вас дорогие кондитеры
              <br />
            </p>
          </div>
          <div className={photo}>
            <StaticImage
              src={img2}
              alt="wowmilota"
              placeholder="dominantColor"
            />
            <StaticImage
              src={img3}
              alt="wowmilota"
              placeholder="dominantColor"
            />
          </div>
        </div>
      </div>

      <div className={three}>
        <div />
        <div className={right}>
          <p>
            <span>
              А если что-то пошло не так и вам не понравился результат?
            </span>
            <br />
            Ничего страшного, просто напишите нам, и поясните ситуацию. Мы
            всегда найдем выход из сложившейся ситуации.
          </p>
        </div>
      </div>

      <div className={four} id="question">
        <StaticImage src={img4} alt="wowmilota" placeholder="dominantColor" />
      </div>

      <div className={eight}>
        <div className={left}>
          <p>
            <span>ОТВЕТЫ НА ЧАСТОЗАДАВАЕМЫЕ ВОПРОСЫ</span>
            <br />
            <br />
            <span>Какой срок изготовления ваших изделий?</span>
            <br />
            Срок изготовления составляет от 2-5 рабочих дней. В самые пиковые
            сезоны от 7-10 рабочих дней.
            <br />
            <br />
            <span>Если необходим срочный заказ?</span>
            <br />
            Если вам необходим заказ к определенной дате, обязательно пропишите
            это при оформлении заказа. Мы всегда стараемся сделать все возможное
            и идем вам навстречу по мере возможностей.
            <br />
            Если же вам необходим заказ со сроком «к завтрешнему дню» и в
            наличии такового не оказалось, то стоимость выполнения работ
            увеличивается на 10%, так как беря срочный заказ нам приходится
            корректировать весь производственный процесс дня.
            <br />
            <br />
            <span>Вы работаете по предоплате или полной оплате?</span>
            <br />
            Так как ваши заказы имеют индивидуальный характер, в работу заказ
            принимается только после полной оплаты, в том числе составление
            макета/эскиза.

          </p>
        </div>
        <div className={right}>
          <p>
            <span>Вы упаковываете подложки?</span>
            <br />
            Мы заботимся о том, чтобы ваши подложки были доставлены до вас в
            чистоте, поэтому каждый заказ мы упаковываем пищевой пленкой.
            <br />
            <br />
            <span>Как скоро выветривается запах жженого дерева?</span>
            <br />
            При производстве подложек мы используем лазерное высокоточное
            оборудование, которое лазерным лучом осуществляет раскрой фанеры и
            гравировку, поэтому сразу после изготовления осуществляется запах
            дерева. Он выветривается в течении 2-3 суток на открытом воздухе.
            <br />
            <br />
          </p>
        </div>
      </div>

      <div className={five}>
        <div className={left}>
          <p>
            Мы очень ценим длительные партнерские отношения. Всегда поддерживаем
            и идем на встречу, делаем скидки постоянным и крупным клиентам.
            <br />
            <br />
            <span>
              Отправляем товар по всей территории РФ почтой России или
              транспортными компаниями.
            </span>{" "}
            <br /> <br />
            Мы рады видеть, как ваш бизнес развивается, а наш товар украшает
            ваши фотографии. Рады новым знакомствам и открыты к новым
            предложениям.
          </p>
        </div>
        <div className={right} id="payment">
          <StaticImage
            src={img5}
            alt="wowmilota"
            layout="constrained"
            placeholder="dominantColor"
          />
        </div>
      </div>

      <div className={six}>
        <div className={left}>
          <StaticImage
            src={img6}
            alt="wowmilota"
            layout="constrained"
            placeholder="dominantColor"
          />
        </div>
        <div className={right}>
          <p>
            <span>ДОСТАВКА</span>
            <br />
            <br />
            Все изделия изготавливаются в порядке очереди. Срок изготовления
            составляет от 2-5 рабочих дней. В самые пиковые сезоны от 7 -10
            рабочих дней.
            <br />
            <br />
            Если вы оформляете заказ к определенной дате, то пожалуйста укажите
            об этом в комментарии к заказу.
            <br />
            <br />
            Мы всегда идем навстречу клиентам и стараемся максимально оперативно
            отдавать все ваши заказы.
            <br />
            <br />
            Но если случается так, что вам необходим заказ со сроком "к
            завтрашнему дню", то за срочность придется доплатить 10% к заказу,
            так как беря срочный заказ нам приходится корректировать весь
            производственный процесс дня.
            <br />
          </p>
        </div>
      </div>

      <div className={eight}>
        <div className={left}>
          <p>
            <span>ОПЛАТА</span>
            <br />
            <br />
            Оплата для физических лиц возможна на карту Сбербанка/ВТБ/Тинькофф.
            Мы вышлем реквизиты после согласования условий заказа.
            <br />
            <br />
            Оплата для юридических лиц на расчетный счет с составлением всех
            необходимых документов. Осуществляем доставку из Красноярска по всей
            территории РФ Почтой России или СДЭКом Стоимость доставки зависит от
            веса и размеров вашего заказа.
          </p>
        </div>
        <div className={right}>
          <p>
            <span>
              Если вы из Красноярска, то при оформлении заказа мы обговорим
              удобный для вас вариант самовывоза:
            </span>
            <br />
            Центральный район г. Красноярска
            <br />
            Свердловский район г. Красноярска (Пашенный)
            <br />
            <br />
            Если у вас остались вопросы пишите нам напрямую в{" "}
            <a style={{ fontWeight: 600 }} href="https://wa.me/79029111546">
              WhatsApp
            </a>{" "}
            или звоните по номеру{" "}
            <a style={{ fontWeight: 600 }} href="tel:+79029111546">
              {" "}
              +7 (902) 911 15 46
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Aboutinfo;
