// extracted by mini-css-extract-plugin
export var about = "about-module--about--94ee5";
export var container = "about-module--container--59954";
export var eight = "about-module--eight--118ec";
export var five = "about-module--five--df829";
export var four = "about-module--four--8b443";
export var info = "about-module--info--76f14";
export var left = "about-module--left--45d70";
export var one = "about-module--one--be70b";
export var photo = "about-module--photo--8ac41";
export var right = "about-module--right--baa8d";
export var six = "about-module--six--d42d2";
export var three = "about-module--three--c429e";
export var two = "about-module--two--ec964";