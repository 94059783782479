import React from 'react';
import Aboutinfo from '../components/pages/aboutinfo';
import Header from '../components/header'
import MetaTag from '../components/metaTag'

function About() {
  return (
    <main className='aboutpage'>
      <MetaTag title='О нас | Wowmilota'/>
      <Header color="#225D6F" background="hsla(0,0%,97%,.75)" buttonColor="rgba(34,93,111,.2)" socialColor="rgba(255, 255, 255, 1)" mainPage={false}/>
      <Aboutinfo />
    </main>
  )
}

export default About;
